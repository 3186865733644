<template>
  <data-table
    :data="properties"
    class="caas-table"
    @row-click="handleRowClick"
  >
    <el-table-column
      label="Property Name"
      prop="property.name"
      sortable
    >
      <template slot-scope="scope">
        {{ scope.row.property.name }}
        <el-tag
          v-if="scope.row.property.premium"
          size="mini"
        >
          Premium
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      label="Company Name"
      prop="company.human_name"
      sortable
    />
    <el-table-column
      label="Service Status"
      prop="property.service_status"
      sortable
    >
      <template slot-scope="scope">
        <el-tag
          size="medium"
          :type="getStatusTagType(scope.row.property.service_status)"
          disable-transitions
        >
          {{ scope.row.property.service_status }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      label="Timezone"
      prop="timezone"
      sortable
    />
    <el-table-column
      label="Poster"
      prop="assigned_to.name"
      sortable
    />
    <el-table-column
      label="Posting Day"
      prop="posting_day"
      sortable
    />
    <el-table-column
      label="Last Posting Completed"
      prop="posting_completed"
      sortable
    >
      <template slot-scope="scope">
        {{ formatDate(scope.row.posting_completed) }}
      </template>
    </el-table-column>
    <el-table-column
      label="Task Status"
      width="150"
      sortable
      :sort-method="sortTaskStatus"
    >
      <template slot-scope="scope">
        <el-tag
          v-if="scope.row.posting_completed[0] && isToday(parseISO(scope.row.posting_completed[0]))"
          type="success"
          size="medium"
        >
          Complete
        </el-tag>
        <el-tag
          v-else-if="scope.row.posting_incomplete_reason[0] && isToday(parseISO(scope.row.posting_incomplete_reason[0]['timestamp']))"
          type="warning"
          size="medium"
        >
          Unable to Post
        </el-tag>
      </template>
    </el-table-column>
  </data-table>
</template>

<script>
import { formatDistanceToNow, isToday, parseISO } from 'date-fns'
import DataTable from '@/components/tables/DataTable'

import { getStatusTagType } from '@/utils/rooof'

export default {
  name: 'CaasQueueTable',
  components: {
    'data-table': DataTable
  },
  props: {
    properties: {
      type: Array,
      required: true
    }
  },
  created () {
    this.isToday = isToday
    this.parseISO = parseISO
    this.getStatusTagType = getStatusTagType
  },
  methods: {
    /**
     * Handles when a row in the data table is clicked.
     * Navigates to the property's full service posts page.
     *
     * @param {Object} row
     */
    handleRowClick (row) {
      this.$router.push({ name: 'FullServicePosts', params: { id: row.property.id } })
    },
    /**
     * Sort function for Task Status column
     *
     * @param {Object} a - column row
     * @param {Object} b - column row
     */
    sortTaskStatus (a, b) {
      const aIsDone = a.posting_completed && isToday(parseISO(a.posting_completed))
      const bIsDone = b.posting_completed && isToday(parseISO(b.posting_completed))
      if (aIsDone && !bIsDone) {
        return -1
      } else if (!aIsDone && bIsDone) {
        return 1
      }
      return 0
    },
    /**
     * Format last completed posting date
     *
     * @param {Array} dates
     * @returns {String} - x days/hours ago
     */
    formatDate (dates) {
      if (!dates.length) {
        return ''
      }
      var lastCompletedDate = new Date(parseISO(dates[0]))
      var diffDays = formatDistanceToNow(lastCompletedDate, { addSuffix: true })
      return diffDays
    }
  }
}
</script>

<style lang="scss" scoped>
.caas-table {
  ::v-deep .el-table__row:hover {
    cursor: pointer;
  }
}
</style>
